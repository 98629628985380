<template>
    <settings-wrapper>
        <template v-slot:child>
            <div class="w-90 center mt4 bg-white" tabindex="0" @keydown.esc="handleInviteModal(false)">
                <TeamsHeader />
                <div class="box-border">
                    <div class="box-border-bottom pa3 flex justify-between">
                        <div class="flex flex-column">
                            <div class="b">Members</div>
                            <div>View all Staffs in your organization here</div>
                        </div>
                        <!-- <div>Team members - {{ members.length }}</div> -->
                        <router-link to="#" rel="modal:open">
                            <button
                                class="ml2"
                                @click.prevent="handleInviteModal(true, 'isInvite')"
                                :disabled="emailVerifiedAt === null"
                                :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                            >
                                + Invite someone
                            </button>
                        </router-link>
                    </div>
                    <!-- <div class="box-border-bottom pa3 flex justify-between">
                    <div class="db-l dn"></div>
                    <div class="flex">
                        <router-link to="/settings/roles" style="color: #132c8c" @click.prevent>
                            <button class="btn2 editIcon">
                                <span>Edit roles</span>
                            </button>
                        </router-link>
                       
                    </div>
                </div> -->
                    <!-- desktop -->
                    <div class="mt3">
                        <div class="active-members">
                            <div
                                class="flex justify-between b box-border-bottom pa3 items-center"
                                style="border: 1px solid #e3e8ee; border-radius: 4px 4px 0 0; background: #f9fafb"
                            >
                                <div class="w-20">Name</div>
                                <div class="w-30 pl2">Email Address</div>
                                <div class="w-30 pl4">Role</div>
                                <div class="">Actions</div>
                                <!-- <div class="w-20"></div> -->
                            </div>

                            <div
                                class="flex justify-between box-border-bottom pa3 items-center"
                                v-for="member of members"
                                v-bind:key="member?._id"
                            >
                                <div class="w-20">{{ member?.name }}</div>
                                <div class="w-30 mr2" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                                    {{ member?.email }}
                                </div>
                                <div class="w-30">
                                    <div
                                        v-if="member?.role === 'owner'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Business Owner
                                    </div>

                                    <div
                                        v-else-if="member?.role === 'admin'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Admin
                                    </div>

                                    <div
                                        v-else-if="member?.role === 'salesTeller'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Sales Teller
                                    </div>

                                    <div
                                        v-else-if="member?.role === 'hr'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        HR
                                    </div>

                                    <div
                                        v-else-if="member?.role === 'branchManager'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Expense Manager
                                    </div>

                                    <div
                                        v-else-if="member?.role === 'expenseManager'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Branch Manager
                                    </div>

                                    <div
                                        v-else-if="member?.role === 'inventoryManager'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Inventory Manager
                                    </div>
                                    <div v-else class="w-60 tc pa1 br-pill" style="background: #edf0fd; color: #132c8c">
                                        {{ member.role }}
                                    </div>
                                </div>
                                <!-- <div
                                v-if="member?.role !== 'owner' && member?.role !== 'admin'"
                                class="w-20 pointer"
                                style="color: #132c8c"
                                @click.prevent="
                                    () => {
                                        handleInviteModal(true, 'isChangeRole')
                                        handleChangeRoleUserDetails(member)
                                    }
                                "
                            >
                                Change Role
                            </div>
                            <div v-else class="w-20">-</div>

                            <div
                                v-if="member?.role !== 'owner' && member?.role !== 'admin'"
                                class="w-20 pointer"
                                style="color: #132c8c"
                                @click.prevent="
                                    () => {
                                        handleInviteModal(true, 'isDelete')
                                        handleChangeRoleUserDetails(member)
                                    }
                                "
                            >
                                Remove
                            </div>

                            <div v-else class="w-20">-</div> -->
                                <!-- <div class="pa2 pv2" style="border-radius: 4px">
                                <img src="../../assets/images/action2.svg" alt="" />
                            </div> -->
                                <div v-if="member?.role !== 'owner'" class="items-center pv3 pr3 tc">
                                    <div class="dropdown" style="position: relative; cursor: pointer">
                                        <a
                                            class="dropdown-togglex"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img src="../../assets/images/action2.svg" alt="" />
                                        </a>

                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li
                                                class="dropdown-itemx flex gap8 items-center"
                                                @click.prevent="
                                                    () => {
                                                        handleInviteModal(true, 'isChangeRole')
                                                        handleChangeRoleUserDetails(member)
                                                    }
                                                "
                                            >
                                                <span><img src="@/assets/images/pen.svg" /></span><span>Edit</span>
                                            </li>
                                            <li
                                                class="dropdown-itemx flex gap8 items-center"
                                                @click.prevent="
                                                    () => {
                                                        handleInviteModal(true, 'isDelete')
                                                        handleChangeRoleUserDetails(member)
                                                    }
                                                "
                                            >
                                                <span><img src="@/assets/images/delete.svg" /></span><span>Delete</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-else class="pa3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-if="pendingInvites.length" class="pending-invites mt5" style="border-radius: 4px 4px 0 0">
                        <div class="pb2">
                            <h3 class="pa2" style="margin-bottom: 0">Pending Invites</h3>
                            <p class="pa2">People that haven’t been invited.</p>
                        </div>
                        <div class="box-border">
                            <div
                                class="flex justify-between b box-border-bottom pa3 items-center"
                                style="border: 1px solid #e3e8ee; border-radius: 4px 4px 0 0; background: #f9fafb"
                            >
                                <div class="w-30 mr2">Email Address</div>
                                <div class="w-30">Role</div>
                                <div class="w-20 db-l dn">Actions</div>
                                <div class="dn-l db">Actions</div>
                                <div class="w-20 db-l dn"></div>
                            </div>

                            <div
                                class="flex justify-between box-border-bottom pa3 items-center"
                                v-for="invite of pendingInvites"
                                v-bind:key="invite?._id"
                            >
                                <div class="w-30 mr2" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                                    {{ invite?.email }}
                                </div>
                                <div class="w-30">
                                    <div
                                        v-if="invite?.role === 'owner'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Business Owner
                                    </div>

                                    <div
                                        v-else-if="invite?.role === 'admin'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Admin
                                    </div>

                                    <div
                                        v-else-if="invite?.role === 'salesTeller'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Sales Teller
                                    </div>

                                    <div
                                        v-else-if="invite?.role === 'hr'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        HR
                                    </div>

                                    <div
                                        v-else-if="invite?.role === 'branchManager'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Expense Manager
                                    </div>

                                    <div
                                        v-else-if="invite?.role === 'expenseManager'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Branch Manager
                                    </div>

                                    <div
                                        v-else-if="invite?.role === 'inventoryManager'"
                                        class="w-60 tc pa1 br-pill"
                                        style="background: #edf0fd; color: #132c8c"
                                    >
                                        Inventory Manager
                                    </div>

                                    <div
                                        v-else
                                        class="w-60 tc pa1 br-pill"
                                        style="
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            background: #edf0fd;
                                            color: #132c8c;
                                        "
                                    >
                                        {{ invite.role }}
                                    </div>
                                </div>
                                <div
                                    v-if="invite?.role !== 'owner' && invite?.role !== 'admin'"
                                    class="w-20 pointer db-l dn"
                                    style="color: #132c8c"
                                    @click.prevent="
                                        () => {
                                            handleInviteModal(true, 'isChangeRole')
                                            handleChangeRoleUserDetails(invite, 'invite')
                                        }
                                    "
                                >
                                    Change Role
                                </div>
                                <div v-else class="w-20 db-l dn">-</div>

                                <div
                                    v-if="invite?.role !== 'owner' && invite?.role !== 'admin'"
                                    class="w-10 pointer pv3 mr3 db-l dn"
                                    style="color: #132c8c; border: 1px solid #e3e8ee; text-align: center; border-radius: 4px"
                                    @click.prevent="
                                        () => {
                                            handleInviteModal(true, 'isDelete')
                                            handleChangeRoleUserDetails(invite, 'invite')
                                        }
                                    "
                                >
                                    Remove
                                </div>

                                <div
                                    v-if="invite?.role !== 'owner' && invite?.role !== 'admin'"
                                    class="w-10 pointer pv3 db-l dn"
                                    style="
                                        color: white;
                                        border: 1px solid #132c8c;
                                        text-align: center;
                                        background-color: #132c8c;
                                        border-radius: 4px;
                                    "
                                    @click.prevent="resendTeamMemberInvite(invite.email)"
                                >
                                    Resend
                                </div>

                                <div v-else class="w-20 db-l dn">-</div>

                                <div
                                    v-if="invite?.role !== 'owner' && invite?.role !== 'admin'"
                                    class="items-center pv3 pr3 tc dn-l db"
                                >
                                    <div class="dropdown" style="position: relative; cursor: pointer">
                                        <a
                                            class="dropdown-togglex"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img src="../../assets/images/action2.svg" alt="" />
                                        </a>

                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li
                                                class="dropdown-itemx flex gap8 items-center"
                                                @click.prevent="
                                                    () => {
                                                        handleInviteModal(true, 'isChangeRole')
                                                        handleChangeRoleUserDetails(invite, 'invite')
                                                    }
                                                "
                                            >
                                                <span><img src="@/assets/images/pen.svg" /></span><span>Change Role</span>
                                            </li>
                                            <li
                                                class="dropdown-itemx flex gap8 items-center"
                                                @click.prevent="resendTeamMemberInvite(invite.email)"
                                            >
                                                <span><img src="@/assets/images/undo.svg" /></span><span>Resend</span>
                                            </li>
                                            <li
                                                class="dropdown-itemx flex gap8 items-center"
                                                @click.prevent="
                                                    () => {
                                                        handleInviteModal(true, 'isDelete')
                                                        handleChangeRoleUserDetails(invite, 'invite')
                                                    }
                                                "
                                            >
                                                <span><img src="@/assets/images/delete.svg" /></span><span>Remove</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-else class="pa3"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- mobile -->
                <!-- <div class="dn-l db" v-for="member of members" :key="member?._id">
                    <div class="pa3 box-border-bottom">
                        <div class="flex justify-between pb2">
                            <div style="color: #84818a">Name</div> -->
                <!-- <div class="font-w1">{{ member?.name }}</div>
                        </div>
                        <div class="flex justify-between pb2">
                            <div style="color: #84818a">Email</div>
                            <div class="font-w1">{{ member?.email }}</div>
                        </div>
                        <div class="flex justify-between pb2">
                            <div style="color: #84818a">Role</div>
                            <div class="font-w1 br-pill pa2 ttc" style="background: #edf0fd; color: #132c8c">
                                <span v-if="member.role === 'branchManager'"> Branch Manager</span>
                                <span v-else-if="member.role === 'salesTeller'">Sales Teller</span>
                                <span v-else-if="member.role === 'owner'">Business Owner</span>
                                <span v-else-if="member.role === 'expenseManager'">Expense Manager</span>
                                <span v-else-if="member.role === 'inventoryManager'">Inventory Manager</span>
                                <span v-else>{{ member?.role }}</span>
                            </div>
                        </div>
                        <div class="flex justify-between pb2" v-if="member?.role !== 'owner'">
                            <div style="color: #84818a">Action</div>
                            <div
                                class="font-w1"
                                style="color: #132c8c"
                                @click.prevent="
                                    () => {
                                        handleInviteModal(true, 'isChangeRole')
                                        handleChangeRoleUserDetails(member)
                                    }
                                "
                            >
                                Change Role
                            </div>
                        </div>
                        <div class="flex justify-between pb2">
                            <div style="color: #84818a"></div>
                            <div class="font-w1">
                                <router-link
                                    to="#"
                                    v-if="member?.role !== 'owner' && member?.role !== 'admin'"
                                    style="color: #132c8c"
                                    @click.prevent="
                                        () => {
                                            handleInviteModal(true, 'isDelete')
                                            handleChangeRoleUserDetails(member)
                                        }
                                    "
                                    >Remove
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div v-if="pendingInvites.length" class="pending-invites">
                        <h3 class="pa2">PENDING INVITES</h3>
                        <div class="flex justify-between b box-border-bottom pa3 items-center">
                            <div class="w-30 mr2">Email Address</div>
                            <div class="w-30">Role</div>
                            <div class="w-20">Actions</div>
                            <div class="w-20"></div>
                        </div>

                        <div
                            class="flex justify-between box-border-bottom pa3 items-center"
                            v-for="invite of pendingInvites"
                            v-bind:key="invite?._id"
                        >
                            <div class="w-30 mr2" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                                {{ invite?.email }}
                            </div>
                            <div class="w-30">
                                <div
                                    v-if="invite?.role === 'owner'"
                                    class="w-60 tc pa1 br-pill"
                                    style="background: #edf0fd; color: #132c8c"
                                >
                                    Business Owner
                                </div>

                                <div
                                    v-else-if="invite?.role === 'admin'"
                                    class="w-60 tc pa1 br-pill"
                                    style="background: #edf0fd; color: #132c8c"
                                >
                                    Admin
                                </div>

                                <div
                                    v-else-if="invite?.role === 'salesTeller'"
                                    class="w-60 tc pa1"
                                    style="background: #edf0fd; color: #132c8c"
                                >
                                    Sales Teller
                                </div>

                                <div
                                    v-else-if="invite?.role === 'hr'"
                                    class="w-60 tc pa1 br-pill"
                                    style="background: #edf0fd; color: #132c8c"
                                >
                                    HR
                                </div>

                                <div
                                    v-else-if="invite?.role === 'branchManager'"
                                    class="w-60 tc pa1 br-pill"
                                    style="background: #edf0fd; color: #132c8c"
                                >
                                    Expense Manager
                                </div>

                                <div
                                    v-else-if="invite?.role === 'expenseManager'"
                                    class="w-60 tc pa1 br-pill"
                                    style="background: #edf0fd; color: #132c8c"
                                >
                                    Branch Manager
                                </div>

                                <div
                                    v-else-if="invite?.role === 'inventoryManager'"
                                    class="w-60 tc pa1 br-pill"
                                    style="background: #edf0fd; color: #132c8c"
                                >
                                    Inventory Manager
                                </div>

                                <div v-else class="w-60 tc pa1 br-pill" style="background: #edf0fd; color: #132c8c">
                                    {{ invite.role }}
                                </div>
                            </div>
                            <div
                                v-if="invite?.role !== 'owner' && invite?.role !== 'admin'"
                                class="w-20 pointer"
                                style="color: #132c8c"
                                @click.prevent="
                                    () => {
                                        handleInviteModal(true, 'isChangeRole')
                                        handleChangeRoleUserDetails(invite, 'invite')
                                    }
                                "
                            >
                                Change Role
                            </div>
                            <div v-else class="w-20">-</div>

                            <div
                                v-if="invite?.role !== 'owner' && invite?.role !== 'admin'"
                                class="w-20 pointer"
                                style="color: #132c8c"
                                @click.prevent="
                                    () => {
                                        handleInviteModal(true, 'isDelete')
                                        handleChangeRoleUserDetails(invite, 'invite')
                                    }
                                "
                            >
                                Remove
                            </div>

                            <div v-else class="w-20">-</div>
                        </div>
                    </div>
                </div> -->

                <div :class="state?.isDelete && 'jquery-modal blocker current'" @click.prevent="onClickOutsideInviteRef">
                    <div class="w-90 mv3 center" :class="!state.isDelete ? 'modal2' : 'modal-target2'">
                        <div class="box-border" style="background: white" ref="deleteModalRef">
                            <div class="box-border-bottom ph3 pb3 pt4">
                                <div v-if="state.isChangeRole == 'member'" class="f4 b pb3">Remove Team Member</div>
                                <div v-else class="f4 b pb3">Delete Invite</div>
                                <div v-if="state.isChangeRole == 'member'" class="lh-copy pb3">
                                    By doing this, the member will no longer be able to access this business anymore. Do you want
                                    to continue?
                                </div>
                                <div v-else class="lh-copy pb3">
                                    By doing this, the entity will no longer be able to accept this invite. Do you want to
                                    continue?
                                </div>
                                <div class="flex items-center">
                                    <img src="../../assets/images/blue-checkbox.svg" alt="" />
                                    <div class="pl2">
                                        Confirm that you want to delete
                                        {{ state.isChangeRole == 'member' ? 'team member' : 'invite' }}
                                    </div>
                                </div>
                            </div>
                            <div class="mv3 pa3">
                                <button class="btn2" @click.prevent="handleInviteModal(false, 'isDelete')">Cancel</button>
                                <button
                                    class="ml3"
                                    @click.prevent="handleRemoveMember"
                                    :disabled="state.disabledButton"
                                    style="background: #eb5757"
                                >
                                    {{ state.disabledButton ? 'Deleting, Please wait...' : 'Delete' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- modal invite team -->
                <div :class="state?.isInvite && 'jquery-modal blocker current'" @click="onClickOutsideInviteRef">
                    <div class="w-90 mv3 center" :class="!state.isInvite ? 'modal2' : 'modal-target2'">
                        <form
                            class="box-border relative"
                            style="background: white; border-radius: 8px;"
                            ref="inviteModalRef"
                            @submit.prevent="handleInvite"
                        >
                          <div class="flex justify-between box-border-bottom b pl3 pr3 pt4">
                            <div class="font16">Invite Team Member</div>
                            <router-link
                                to="#"
                                class=""
                                @click.prevent="handleInviteModal(false, 'isInvite')"
                            >
                                <img src="../../assets/images/exit-icon.svg" alt=""  />
                            </router-link>
                            
                          </div>
                            <div class="pa3 box-border-bottom">
                                <div class="flex flex-column pb3 items-start">
                                    <label class="pb2 b font14" for="inviteEmail">Email</label>
                                    <input
                                        v-model="state.inviteEmail"
                                        id="inviteEmail"
                                        type="text"
                                        placeholder="Enter email"
                                        class="border-team pa2 pv3 w-100"
                                        required
                                    />
                                </div>
                             
                                <div class="flex flex-column items-start">
                                    <label class="pb2 b font14">Choose user role</label>
                                    <select
                                        v-model="state.inviteRole"
                                        name="inviteRole"
                                        class="pa2 pv3 w-100 border-team"
                                        id="inviteRole"
                                        required
                                    >
                                        <option value="">Select role</option>
                                        <option v-for="role of state.roles" v-bind:key="role._id" :value="role">
                                            {{ role.roleName }}
                                        </option>
                                        <!-- <option value="admin">Admin</option> -->
                                        <!-- <option value="branchManager">Branch Manager</option> -->
                                        <!-- <option value="salesTeller">Sales Teller</option> -->
                                        <!-- <option value="expenseManager">Expense Manager</option> -->
                                        <!-- <option value="inventoryManager">Inventory Manager</option> -->
                                    </select>
                                </div>
                                <div class="flex flex-column items-start pt3">
                                    <label class="pb2 b font14">Select the business/branch user will have access to (multiple selection is allowed)</label>
                                    <vue3-tags-input
                                        v-model:tags="state.businesses"
                                        v-model="state.business"
                                        :select="true"
                                        :select-items="state.allBusinesses"
                                        placeholder="Select one or more business"
                                        @on-select="handleSelectedBusiness"
                                        @on-tags-changed="handleChangeBusiness"
                                        class="w-100 pv2 border-team"
                                    >
                                        <template #item="{ tag }">
                                            {{ tag.name }}
                                        </template>
                                        <template #no-data> No Data </template>
                                        <template #select-item="tag">
                                            {{ tag.name }}
                                        </template>
                                    </vue3-tags-input>
                                </div>
                            </div>
                            <div class="flex justify-between pa3">
                                <div class="db-l dn"></div>
                                <div>
                                    <button class="btn2" @click.prevent="handleInviteModal(false, 'isInvite')">Cancel</button>
                                    <button class="ml2" :disabled="state.disabledButton" type="submit">
                                        {{ state.disabledButton ? 'Inviting, Please wait...' : 'Invite Member' }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div :class="state?.isChangeRole && 'jquery-modal blocker current'" @click="onClickOutsideInviteRef">
                    <div class="w-90 mv3 center" :class="!state.isChangeRole ? 'modal2' : 'modal-target2'">
                        <form
                            class="box-border relative"
                            style="background: white"
                            ref="changeRoleModalRef"
                            @submit.prevent="handleChangeRole"
                        >
                            <router-link
                                to="#"
                                class="absolute top--1 right--2"
                                @click.prevent="handleInviteModal(false, 'isChangeRole')"
                            >
                                <img src="../../assets/images/close-icon-2.png" alt="" class="w-40 h-40" />
                            </router-link>
                            <div class="box-border-bottom f4 b pa3 pt4">
                                Change <span class="red">{{ `${state.memberName}'s` }}</span> Role
                            </div>
                            <div class="pa3 box-border-bottom">
                                <div class="alert alert-warning">
                                    Logging out and logging back in is required for changes in user access rights on this role to
                                    take effect.
                                </div>
                                <div class="flex flex-column items-start">
                                    <label class="pb2 font-w1">Choose</label>
                                    <select v-model="state.inviteRole" name="inviteRole" class="pa2 w-100" id="inviteRole">
                                        <option v-for="role of state.roles" v-bind:key="role._id" :value="role">
                                            {{ role.roleName }}
                                        </option>
                                    </select>
                                </div>
                                <div class="flex flex-column items-start mt2">
                                    <label class="pb2 font-w1">Change business</label>
                                    <vue3-tags-input
                                        v-model:tags="state.businesses"
                                        v-model="state.business"
                                        :select="true"
                                        :select-items="state.allBusinesses"
                                        placeholder="Select one or more business"
                                        @on-select="handleSelectedBusiness"
                                        @on-tags-changed="handleChangeBusiness"
                                        class="w-100"
                                    >
                                        <template #item="{ tag }">
                                            {{ tag.name }}
                                        </template>
                                        <template #no-data> No Data </template>
                                        <template #select-item="tag">
                                            {{ tag.name }}
                                        </template>
                                    </vue3-tags-input>
                                </div>
                            </div>
                            <div class="flex justify-between pa3">
                                <div class="db-l dn"></div>
                                <div>
                                    <button class="btn2" @click.prevent="handleInviteModal(false, 'isChangeRole')">Cancel</button>
                                    <button class="ml2" :disabled="state.disabledButton" type="submit">
                                        {{ state.disabledButton ? 'Changing Role, Please wait...' : 'Change Role' }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Vue3TagsInput from 'vue3-tags-input'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import TeamsHeader from '@/views/Settings/widgets/TeamsHeader'

export default {
    name: 'Teams',
    components: { Vue3TagsInput, SettingsWrapper, TeamsHeader },

    setup() {
        const store = useStore()
        const members = computed(() => store.state.Settings.members)
        const pendingInvites = computed(() => store.state.Settings.pendingInvites)
        const emailVerifiedAt = computed(() => store?.state?.Auth?.userData?.emailVerifiedAt)
        const customRoles = computed(() => store.state.Settings.customRoles)
        const businesses = computed(() => store?.state?.Settings?.businesses)

        const inviteModalRef = ref(null)
        const deleteModalRef = ref(null)
        const changeRoleModalRef = ref(null)

        const initialState = () => ({
            isDelete: false,
            isInvite: false,
            isChangeRole: false,
            isChangeRoleType: 'member',
            inviteEmail: '',
            inviteRole: '',
            disabledButton: false,
            memberName: '',
            memberId: '',
            roles: [
                {
                    roleName: 'Business Owner',
                    _id: 'owner',
                },
                // {
                //     roleName: 'Sales Teller',
                //     _id: 'salesTeller'
                // }
            ],
            business: '',
            businesses: [],
            selectedBusinesses: [],
            allBusinesses: [],
        })
        const state = reactive(initialState())

        const handleSelectedBusiness = (business) => {
            state.businesses.push(business)
            state.selectedBusinesses.push(business.org)
        }

        const handleChangeBusiness = (businesses) => {
            state.businesses = businesses
            state.selectedBusinesses = businesses.map((business) => business.org)
        }

        const handleInviteModal = (payload = false, modal) => {
            if (modal) {
                state[modal] = payload
            } else {
                state.isInvite = false
                state.isDelete = false
                state.isChangeRole = false
            }

            if (state.isInvite || state.isDelete || state.isChangeRole) {
                if (!payload) {
                    Object.assign(state, initialState())
                }
            }

            state.businesses = []
            state.business = ''
        }

        const onClickOutsideInviteRef = (e) => {
            if (
                // eslint-disable-next-line
                inviteModalRef.value &&
                // eslint-disable-next-line
                !inviteModalRef.value.contains(e.target) &&
                // eslint-disable-next-line
                deleteModalRef.value &&
                // eslint-disable-next-line
                !deleteModalRef.value.contains(e.target) &&
                // eslint-disable-next-line
                changeRoleModalRef.value &&
                !changeRoleModalRef.value.contains(e.target)
            ) {
                const modals = ['isInvite', 'isDelete', 'isChangeRole']

                modals.forEach((modal) => {
                    if (state[modal]) {
                        handleInviteModal(false, modal)
                    }
                })
            }
        }

        const handleInvite = () => {
            state.disabledButton = true
            const payload = {
                email: state.inviteEmail,
                role: state.inviteRole.roleName,
                roleId: state.inviteRole?._id,
                roleAccess: state.inviteRole?.access,
                businesses: state.selectedBusinesses,
            }

            store.dispatch('Settings/inviteTeamMember', payload).then((resp) => {
                if (resp?.success) {
                    handleInviteModal(false)
                    state.disabledButton = false
                    // Object.assign(state, initialState())
                } else {
                    state.disabledButton = false
                }
            })
        }

        const handleChangeRoleUserDetails = (user, type = 'member') => {
            // console.log(user)
            state.isChangeRoleType = type
            state.memberName = type === 'member' ? user?.name || '' : user?.email || ''
            state.inviteRole = state.roles.filter(
                (role) => role._id.toString() === user?.roleId || role.roleName === (user?.role || state.inviteRole)
            )[0]
            state.businesses = state.allBusinesses.filter((business) => user?.businessAccess?.includes(business.org.toString()))
            // eslint-disable-next-line
            state.memberId = user?._id || ''
        }

        const handleChangeRole = () => {
            state.disabledButton = true
            const payload = {
                memberId: state.memberId,
                role: state.inviteRole.roleName,
                roleId: state.inviteRole?._id,
                roleAccess: state.inviteRole?.access,
                businesses: state.selectedBusinesses,
            }

            const dispatchPath =
                state.isChangeRoleType === 'member' ? 'Settings/changeTeamMemberRole' : 'Settings/changeTeamInviteRole'

            store.dispatch(dispatchPath, payload).then((resp) => {
                if (resp?.success) {
                    handleInviteModal(false)
                    state.disabledButton = false
                } else {
                    state.disabledButton = false
                }
            })
        }

        const handleRemoveMember = () => {
            state.disabledButton = true

            const dispatchPath = state.isChangeRoleType === 'member' ? 'Settings/removeTeamMember' : 'Settings/removeTeamInvite'
            store.dispatch(dispatchPath, state.memberId).then((resp) => {
                if (resp?.success) {
                    handleInviteModal(false)
                    state.disabledButton = false
                } else {
                    state.disabledButton = false
                }
            })
        }

        const resendTeamMemberInvite = (email) => {
            store.dispatch('Settings/resendTeamMemberInvite', { email })
        }

        watch(
            () => customRoles.value,
            (currValue, preValue) => {
                state.roles = [...state.roles, ...currValue]
            }
        )

        onMounted(() => {
            store.dispatch('Settings/getTeamMembers')
            store.dispatch('Settings/getCustomRoles')
            store.dispatch('Settings/getBusinesses', true).then((resp) => {
                if (resp.status) {
                    state.allBusinesses = resp.data
                }
            })
        })

        return {
            state,
            handleInviteModal,
            handleRemoveMember,
            inviteModalRef,
            onClickOutsideInviteRef,
            deleteModalRef,
            members,
            pendingInvites,
            handleInvite,
            handleChangeRole,
            handleChangeRoleUserDetails,
            resendTeamMemberInvite,
            changeRoleModalRef,
            emailVerifiedAt,
            businesses,
            handleChangeBusiness,
            handleSelectedBusiness,
        }
    },
}
</script>

<style lang="css">
.v3ti .v3ti-tag {
    background: #132c8c;
    /*border: 1px solid #222222;*/
    /*border-radius: 0;*/
}

.v3ti .v3ti-tag .v3ti-remove-tag {
    color: #febf12;
    transition: color 0.3s;
}

.v3ti .v3ti-tag .v3ti-remove-tag:hover {
    color: #ffffff;
}
.font16{
    font-size: 16px;
}
.font14{
    font-size: 14px;
}
.border-team{
    border-radius:8px;
    border: 1.5px solid #D0D9FF;
}
</style>
